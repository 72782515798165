<template>
  <main-layout class="padded">
    <div v-if="organization" class="organization-page pvp-container">
      <div class="organization-page__title">
        <h1 class="title">{{ $t('organizations.organizer') }}</h1>
        <div class="head-box">
          <pvp-btn
            v-if="canEditProfile"
            icon-left="settings"
            @click="openComponent('organization-settings')"
          >
            {{ $t('global.edit') }}
          </pvp-btn>
        </div>
      </div>

      <div class="info">
        <div class="info-avatar">
          <edit-logo
            v-if="canEditProfile"
            :id="id"
            type="organization"
            @updated="fetchOrganization(id)"
          >
            <organization-avatar
              class="info-avatar"
              :src="organization.imgLogo"
            />
          </edit-logo>
          <organization-avatar
            v-else
            class="info-avatar"
            :src="organization.imgLogo"
          />
        </div>
        <div class="info-about">
          <h2 class="info-name">
            <span>
              {{ organization.name }}
            </span>
            <span class="info-name__icon">
              <TrustedIcon
                v-if="organization.isTrust"
                :is-trusted="organization.isTrust"
                type="organization"
              />
            </span>
          </h2>
          <p class="info-description">
            {{ organizationDescription }}
          </p>
        </div>
        <div class="divider"></div>
        <div class="contacts">
          <div class="contacts-title">
            {{ $t('global.contacts') }}
          </div>
          <div class="contacts-list">
            <template v-for="(contact, key) in contacts">
              <div :key="`${key}-type`" class="contacts-type">
                {{ contact.title }}:
              </div>
              <div :key="`${key}-value`" class="contacts-value">
                <a
                  v-if="contact.isLink"
                  :href="concatContactValue(contact.value)"
                  target="_blank"
                >
                  {{ contact.value }}
                </a>
                <template v-else> {{ contact.value }} </template>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="organization-page__tournaments">
        <div class="subtitle">
          {{ $t('tournaments.few') }}
          <pvp-btn
            v-if="canEditRoster"
            icon-left="plus"
            @click="openComponent('tournament-creation')"
          >
            {{ $t('tournaments.create') }}
          </pvp-btn>
        </div>
        <div class="organization-page__game-filter">
          <GamesFilter
            v-model="gamesIds"
            hide-headline
            @input="handleGamesFilter"
          />
        </div>
        <TabList :tabs="tabs" @change-tab="tabListChangeHandler" />
        <div :key="organizationTournamentsGameId">
          <organization-tournaments
            :id="id"
            :key="tournamentsListKey"
            :game-id="organizationTournamentsGameId"
            :can-edit-tournament="canEditTournament"
            :type="tournamentType"
          />
        </div>
      </div>

      <div v-if="ratings.length" class="organization-page__ratings">
        <ul class="ratings-list">
          <li
            v-for="rating in ratings"
            :key="rating.id"
            class="ratings-list__item"
          >
            <h4 class="ratings-list__title">
              <BaseLink
                :to="{
                  name: 'rating',
                  params: { id: rating.id, version: rating.version },
                }"
              >
                {{ rating.name }}
              </BaseLink>
            </h4>
            <Table class="ratings-list__table" :min-width="false">
              <template #thead>
                <TableRow>
                  <TableData :heading="true">
                    <cell-header t="places.single" />
                  </TableData>
                  <TableData :heading="true" text-align="left">
                    <cell-header
                      :t="`ratings.table_${
                        isTeam ? 'team' : 'player'
                      }`"
                    />
                  </TableData>
                  <TableData :heading="true">
                    <cell-header t="ratings.single" />
                  </TableData>
                </TableRow>
              </template>
              <template #tbody>
                <TableRow
                  v-for="(rowData, index) in rating.places"
                  :key="index"
                >
                  <TableData text-align="center">
                    {{ rowData.place }}
                  </TableData>
                  <TableData text-align="left" :padding="false">
                    <BaseTeamLinkWithoutStore
                      v-if="isTeam(rating)"
                      :data="{
                        hash: rowData.hash,
                        name: rowData.name,
                        avatarUrl: rowData.image,
                      }"
                    />
                    <BaseUserLinkWithoutStore
                      v-else
                      :data="{
                        hash: rowData.hash,
                        name: rowData.name,
                        avatarUrl: rowData.image,
                      }"
                    />
                  </TableData>
                  <TableData text-align="center">
                    {{ rowData.finedValue.toFixed(2) }}
                  </TableData>
                </TableRow>
              </template>
            </Table>
            <pvp-btn
              :to="{
                name: 'rating',
                params: { id: rating.id, version: rating.version },
              }"
              :block="true"
              class="about-rating-button"
              variant="boostSecondary"
              >{{ $t('ratings.more') }}
            </pvp-btn>
          </li>
        </ul>
        <button
          v-if="!isAllRatingsDisplay"
          class="organization-page__more-btn"
          @click="showMoreRatings"
        >
          {{ $t('actions.show_more') }}
        </button>
      </div>

      <ContactCard
        v-if="isShowContactCard"
        image-src="https://pvp.vkplay.ru/img/main-layout/bg.svg"
        :title="$t('boost.contact_title')"
        :subtitle="
          $t('boost.contact_org_subtitle', {
            name: organization.name,
          })
        "
        :link="
          organization.contacts.discord ||
          organization.contacts.website
        "
        :button-text="$t('boost.btn_contact_us')"
      />

      <template v-if="members && members.length">
        <div class="subtitle">
          {{ $t('global.composition') }}
          <span class="count">{{ members.length }}</span>
          <template v-if="canEditRoster">
            <pvp-btn
              :disabled="isEdit"
              icon-left="pencil"
              @click="toggleEditMode(true)"
            >
              {{ $t('global.editComposition') }}
            </pvp-btn>
            <pvp-btn
              icon-left="plus"
              @click="openComponent('organization-new-participant')"
            >
              {{ $t('participants.add') }}
            </pvp-btn>
          </template>
        </div>
        <div
          class="line-up"
          :class="{
            edit: isEdit,
            big: members.length > 9,
            all: showLineUp,
          }"
        >
          <div
            v-for="(member, key) in members"
            :key="key"
            class="member"
          >
            <div v-if="isEdit" class="member-settings">
              <pvp-btn
                v-if="member.role !== 'owner'"
                icon-left="cross"
                variant="clear"
                @click="
                  openComponent(
                    'organization-delete-member',
                    member.hash,
                  )
                "
              />
              <user-avatar :src="member.image" />
              <div class="user-info">
                <span class="user-name">{{ member.name }}</span>
                <div class="user-role">
                  <span v-if="member.role === 'owner'" class="owner">
                    <Icon name="crown" inline />
                    {{ $t('players.role_owner') }}
                  </span>
                  <div v-else class="roles">
                    <BaseRadio
                      v-for="(role, index) in inviteRoles"
                      :key="index"
                      :model-value="playersRoles[member.hash]"
                      :value="role"
                      @input="setRadio(member.hash, role)"
                    >
                      {{ $t(`players.role_${role}`) }}
                    </BaseRadio>
                  </div>
                </div>
              </div>
            </div>
            <BaseUserLinkWithoutStore
              v-else
              :display-role="true"
              :image-size="appIsLarge ? 90 : 60"
              :data="{
                hash: member.hash,
                name: member.name,
                avatarUrl: member.avatarUrl,
              }"
              display-name
            >
              <span slot="username" class="wrap">
                {{ member.name }}
              </span>
              <span slot="userrole" class="wrap">
                {{ filterRole(member.role) }}
              </span>
            </BaseUserLinkWithoutStore>
          </div>
          <div
            class="line-up-toggle"
            @click="() => (showLineUp = !showLineUp)"
          >
            {{
              showLineUp ? $t('global.collapse') : $t('global.expand')
            }}
          </div>
        </div>

        <div v-if="isEdit" class="edit-buttons">
          <InfoBox v-if="error" class="error-msg" variant="error">
            {{ error }}
          </InfoBox>
          <pvp-btn
            variant="secondary"
            @click="toggleEditMode(false)"
            >{{ $t('global.cancel') }}</pvp-btn
          >
          <pvp-btn @click="fetchEdit">{{
            $t('save.single')
          }}</pvp-btn>
        </div>
      </template>

      <!-- action modal -->
      <component
        :is="activeComponent.component"
        v-if="activeComponent.component"
        :value="isOpen"
        v-bind="activeComponent.props"
        v-on="activeComponent.methods"
        @input="closeModal"
      />
    </div>
  </main-layout>
</template>

<script>
import OrganizationTournaments from '@components/TournamentComponents/Lists/OrganizationTournaments.vue';
import OrganizationSettings from '@components/OrganizationComponents/OrganizationSettings.vue';
import EditLogo from '@components/BaseComponents/EditLogo.vue';
import TournamentCreation from '@components/TournamentComponents/TournamentCreateModal.vue';
import OrganizationNewParticipant from '@components/OrganizationComponents/OrganizationNewParticipant.vue';
import OrganizationDeleteMember from '@components/OrganizationComponents/OrganizationDeleteMember.vue';
import TrustedIcon from '@components/BaseComponents/TrustedIcon';
import GamesFilter from '@components/GameComponents/GamesFilter.vue';
import TabList from '@components/v2/TabList.vue';
import ContactCard from '@src/components/v2/cards/ContactCard.vue';
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import CellHeader from '@components/TableCells/CellHeader.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseRadio from '@components/BaseComponents/Form/Radio.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import BaseTeamLinkWithoutStore from '@components/BaseComponents/BaseTeamLinkWithoutStore.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';
import { fetchRatingBoards } from '@src/shared/api/rating';

export default {
  components: {
    Icon,
    BaseTeamLinkWithoutStore,
    BaseUserLinkWithoutStore,
    TrustedIcon,
    OrganizationDeleteMember,
    OrganizationNewParticipant,
    TournamentCreation,
    EditLogo,
    OrganizationTournaments,
    OrganizationSettings,
    GamesFilter,
    TabList,
    ContactCard,
    Table,
    TableRow,
    TableData,
    CellHeader,
    BaseRadio,
    BaseLink,
    InfoBox,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      isEdit: false,
      gamesIds: [],
      ratings: [],
      ratingsCurrentPage: 1,
      ratingsTotalPages: 0,
      activeComponent: {},
      tournamentsListKey: 0,
      playersRoles: {},
      activeTab: 0,
      tabs: [
        {
          title: this.$t('tournaments.tabs_upcoming'),
        },
        {
          title: this.$t('tournaments.tabs_current'),
        },
        {
          title: this.$t('tournaments.tabs_completed'),
        },
      ],
      showLineUp: false,
      delMembersList: [],
      error: '',
    };
  },
  computed: {
    ...mapState('organizations', ['inviteRoles']),
    ...mapGetters('organizations', [
      'getOrganization',
      'getOrganizationMembers',
    ]),
    ...mapGetters('application', ['appIsLarge']),
    ...mapGetters('users', ['getUserProfile']),
    ...mapGetters('profile', ['getCurrentProfileHash']),

    organization() {
      return this.getOrganization(this.id);
    },

    organizationDescription() {
      return this.organization.description === 'null' ||
        _.isNull(this.organization.description)
        ? ''
        : this.organization.description;
    },

    organizationTournamentsGameId() {
      return this.gamesIds[0] || 0;
    },

    tournamentType() {
      return [6, 2, 4][this.activeTab];
    },

    isAllRatingsDisplay() {
      return this.ratingsTotalPages === this.ratingsCurrentPage;
    },

    members() {
      const members = this.getOrganizationMembers(this.id)?.filter(
        (hash) => this.delMembersList.includes(hash) === false,
      );
      return members?.map(this.getUserProfile);
    },

    canEditTournament() {
      return this.organization?.abilities?.canEditTournament;
    },

    canEditProfile() {
      return this.organization?.abilities?.canEditProfile;
    },

    canEditRoster() {
      return this.organization?.abilities?.canEditRoster;
    },

    isBoostOrganization() {
      return this.organization?.id === 4739;
    },

    isShowContactCard() {
      return this.contacts.length;
    },

    contacts() {
      const { contacts } = this.organization;

      if (!contacts) {
        return [];
      }

      const variants = [
        {
          title: 'Discord',
          value:
            contacts.discord === 'null' || _.isNull(contacts.discord)
              ? ''
              : contacts.discord,
          isLink: true,
        },
        {
          title: this.$t('global.site'),
          value:
            contacts.website === 'null' || _.isNull(contacts.website)
              ? ''
              : contacts.website,
          isLink: true,
        },
        {
          title: this.$t('global.additionally'),
          value:
            contacts.contacts === 'null' ||
            _.isNull(contacts.contacts)
              ? ''
              : contacts.contacts,
          isLink: false,
        },
      ];

      return variants.filter((variant) => Boolean(variant.value));
    },
  },
  async created() {
    this.initGameFilter();
    await this.fetchOrganization(this.id);
    this.fetchMembers();
    await this.fetchRatings();
  },
  methods: {
    ...mapActions('organizations', [
      'fetchOrganization',
      'fetchOrganizationMembers',
    ]),

    initGameFilter() {
      const { game } = this.$route.query;
      if (game) {
        this.gamesIds.push(Number(game));
      }
    },

    handleGamesFilter(data) {
      const [gameId] = data;
      this.$router.replace({
        query: { ...this.$route.query, game: gameId },
      });
    },

    tabListChangeHandler({ index }) {
      this.activeTab = index;
    },

    concatContactValue(contact) {
      return `${contact.includes('@') ? 'mailto:' : ''}${contact}`;
    },

    async fetchRatings() {
      try {
        this.loadingState.ratings = true;
        const response = await fetchRatingBoards({
          pageNum: this.ratingsCurrentPage,
          pageSize: 2,
          organization: this.organization.id,
        });
        this.ratings = [...this.ratings, ...response.items];
        this.ratingsCurrentPage = response.pagen.pageNum + 1;
        this.ratingsTotalPages = response.pagen.pagesCount;
      } catch (err) {
        // TODO Обработать ошибку
      }
    },

    showMoreRatings() {
      if (this.isAllRatingsDisplay) return;
      this.ratingsCurrentPage += 1;
      this.fetchRatings();
    },

    fetchMembers() {
      this.fetchOrganizationMembers({ id: this.id }).then(() => {
        this.members.forEach(({ hash, role }) => {
          if (role !== 'owner') {
            this.setRadio(hash, role);
          }
        });
      });
    },

    filterRole(role) {
      if (role) {
        const str = this.$t(`players.role_${role}`);
        return `${str[0].toUpperCase()}${str.slice(1)}`;
      }
      return '';
    },

    openComponent(component, data) {
      this.isOpen = true;

      this.activeComponent =
        {
          'organization-settings': {
            component,
            props: {
              id: this.id,
              variant: 'edit',
            },
            methods: {
              success: () => this.fetchOrganization(this.id),
            },
          },

          'tournament-creation': {
            component,
            props: {
              organizationId: this.id,
            },
          },

          'organization-new-participant': {
            component,
            props: {
              id: this.id,
            },
          },

          'organization-delete-member': {
            component,
            props: {
              id: this.id,
              hash: data,
            },
            methods: {
              success: this.markMemberToDel,
            },
          },
        }[component] || {};
    },

    isTeam(ratingObject) {
      return ratingObject?.type === 2;
    },

    closeModal() {
      this.isOpen = false;
      this.activeComponent = {};
    },

    toggleEditMode(isEdit) {
      this.isEdit = isEdit;
      if (isEdit === false) {
        this.delMembersList = [];
      }
    },

    setRadio(hash, value) {
      Vue.set(this.playersRoles, hash, value);
    },

    markMemberToDel(hash) {
      this.delMembersList.push(hash);
      this.closeModal();
    },

    deleteMembers() {
      Promise.all(
        this.delMembersList.map((hash) =>
          api.delete(`/organization/${this.id}/player`, {
            hash,
          }),
        ),
      ).then(() => {
        if (
          this.delMembersList.includes(this.getCurrentProfileHash)
        ) {
          this.fetchOrganization(this.id);
        }
        this.fetchMembers();
        this.toggleEditMode(false);
      });
    },

    fetchEdit() {
      api
        .patch(`/organization/${this.id}/playeredit`, {
          users: Object.entries(this.playersRoles).map(
            ([hash, role]) => ({
              hash,
              role,
            }),
          ),
        })
        .then(() => {
          if (this.delMembersList.length) {
            this.deleteMembers();
          } else {
            this.fetchMembers();
            this.toggleEditMode(false);
          }
        })
        .catch(({ error }) => {
          this.error = error || this.$t('errors.unknown');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';
.title {
  line-height: 1;
  @include min-laptop() {
    font-size: 34px;
  }
  @include max-laptop() {
    font-size: 32px;
  }
}

.subtitle {
  margin-top: 40px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  @include min-tablet() {
    .count {
      margin-left: 0.3em;
    }
    display: flex;
    align-items: baseline;
  }

  .button {
    @include min-tablet() {
      margin-left: 16px;
    }

    @include max-tablet() {
      display: block;
      margin-top: 12px;
      & + .link {
        margin-left: 20px;
      }
    }
  }

  .count {
    font-size: 22px;
    color: rgba(255, 255, 255, 0.57);
  }
}

.info {
  margin-top: 20px;
  background: #2d2d30;
  border-radius: 6px;
  @include min-laptop() {
    display: grid;
    grid-template-columns: auto 1fr auto 1fr;
    grid-template-areas: 'avatar about divider contacts';
    gap: 20px;
    padding: 30px 20px;
  }
  @include max-laptop() {
    padding: 16px 12px;
    margin-left: -12px;
    margin-right: -12px;
  }

  &-avatar {
    width: 136px;
    vertical-align: top;
    flex-shrink: 0;
    grid-area: avatar;
    @include max-laptop() {
      margin: 0 auto;
    }

    .icon {
      height: 136px;
    }
  }

  &-about {
    grid-area: about;
    @include max-laptop() {
      margin-top: 12px;
    }
  }

  &-name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
    line-height: 1.1em;
    color: $white;
    flex-grow: 1;

    @include min-laptop() {
      font-size: 30px;

      ::v-deep .icon {
        width: 30px;
        height: 30px;
      }
    }
    @include max-laptop() {
      font-size: 24px;

      ::v-deep .icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-description {
    font-size: 13px;
    line-height: 1.69;
    color: rgba($white, 0.6);
    margin: 18px 0 0;
  }
}

.divider {
  @include min-laptop() {
    border-left: 1px solid rgba($white, 0.1);
    grid-area: divider;
  }
  @include max-laptop() {
    border-top: 1px solid rgba($white, 0.1);
    margin: 16px 0;
  }
}

.contacts {
  grid-area: contacts;

  @include min-laptop() {
    padding-left: 20px;
  }

  &-list {
    margin-top: 8px;
    @include min-laptop() {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }

  &-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
  }

  &-type {
    font-size: 13px;
    color: rgba($white, 0.5);
    grid-area: auto;
    vertical-align: top;
    margin: 10px 32px 0 0;
  }

  &-value {
    font-size: 14px;
    line-height: 1.43;
    grid-area: auto;
    vertical-align: top;
    @include min-laptop() {
      margin-top: 10px;
    }
  }
}

.line-up {
  background: #2d2d30;
  border-radius: 6px;
  position: relative;
  margin-top: 20px;

  &.edit {
    background-color: transparent;
    border: none;
    padding: 0;

    .member {
      margin-top: 12px;
      @include min-tablet() {
        padding-right: 12px;
      }
    }
  }

  &.big {
    overflow: hidden;

    &:not(.all):not(.edit) {
      height: 420px;
    }

    &:not(.edit) .line-up-toggle {
      display: flex;
    }

    &.all {
      .line-up-toggle {
        height: auto;
        padding: 10px 0;
        position: relative;
      }
    }
  }

  @include min-tablet() {
    padding: 28px 32px;
  }
  @include max-tablet() {
    padding: 16px 12px;
    margin-left: -12px;
    margin-right: -12px;
  }

  &-toggle {
    display: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 125px;
    background: linear-gradient(rgba(#2d2d30, 0.8), #2d2d30 80%);
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 15px;
    line-height: 1.33;
    color: $dodger-blue;
  }

  .member {
    display: inline-block;
    margin-top: 24px;
    @include min-laptop() {
      width: 33%;
    }
    @include tablet-only() {
      width: 50%;
    }
    @include min-tablet() {
      padding-right: 20px;
    }
    @include max-tablet() {
      width: 100%;
    }

    &-settings {
      position: relative;
      background-color: #2a2c3b;
      padding: 24px 28px;
      display: flex;
      align-items: center;

      .button {
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        color: white;
        background-color: rgba(black, 0.25);
      }

      .owner {
        display: flex;
        align-items: center;
        font-size: 13px;

        .icon {
          color: $gold-two;
          margin-right: 4px;
        }
      }

      .user {
        &-avatar {
          margin-right: 1em;
          @include min-laptop() {
            width: 90px;
            height: 90px;
          }
          @include max-laptop() {
            width: 60px;
            height: 60px;
          }
        }
      }

      .roles {
        font-size: 13px;
        margin-top: 10px;
      }
    }
  }

  .user-link {
    max-width: 100%;
  }

  .wrap {
    white-space: normal;
  }
}

.organization-tournaments {
  margin-top: 20px;
}

.tabs-menu {
  margin-top: 20px;
  @include max-laptop() {
    width: auto;
    margin-left: -12px;
    margin-right: -12px;
  }
}

.edit-buttons {
  text-align: center;
  margin-top: 20px;

  .button {
    min-width: 160px;
    @include max-tablet() {
      margin: 12px 6px 0;
    }

    & + .button {
      @include min-tablet() {
        margin-left: 16px;
      }
    }
  }
}

.error-msg {
  margin: 12px 0;
}

.organization-page__title {
  @include min-tablet() {
    display: flex;
    align-items: center;
  }

  &-box {
    display: flex;
    @include min-tablet() {
      margin-left: 16px;
    }
    @include max-tablet() {
      margin-top: 12px;
    }
  }
}

.organization-page__tournaments,
.organization-page__ratings {
  margin-bottom: 20px;
  @include min-tablet() {
    margin-bottom: 40px;
  }
}
.organization-page__game-filter {
  margin-bottom: 20px;
}
.organization-page__more-btn {
  color: #16a7ff;
  font-weight: bold;
  background: none;
  border: none;
  display: block;
  margin: 20px auto;
}

.ratings-list {
  list-style-type: none;
  @include min-laptop() {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 12px;
  }
}

.ratings-list__item {
  margin-bottom: 12px;
  @include min-laptop() {
    margin-bottom: 0;
  }
}

.ratings-list__title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;

  @include min-tablet() {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  a {
    color: #f5f5f7;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }
}

.head-box {
  margin-top: 16px;
  margin-left: 0;
  @include min-tablet() {
    margin-top: 0;
    margin-left: 16px;
  }
}

.about-rating-button {
  @include min-tablet() {
    margin-top: 12px;
  }
}

.info-name__icon {
  line-height: 0;
}
</style>
